<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbwhatsapks.net/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/gb-logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbwhatsapks.net/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbwhatsapks.net/" aria-current="page">GB WhatsApp</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/download-gbwhatsapp/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/gb-whatsapp-old-versions/">Old Versions</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/blogs/">Blogs</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <section id="section-1" class="section-content">
            <Breadcrumb :breadcrumbs="breadcrumbs" />
            <h1 class="section-1-blog-head">
              How to Transfer GB WhatsApp Messages to WhatsApp?
            </h1>

            <p class="writter-content">
              While WhatsApp provides various features for staying connected with our contacts, many users seek additional functionalities. To cater to this demand, modified apps like WhatsApp Plus and GBWhatsApp have been developed. However, these modified apps come with their drawbacks, and prolonged use can result in account suspension. 
            </p>

            <div class="lazy-picture-container writer-banner full-width">
              <picture><img width="auto" height="auto" alt="gbwhatsapp chat" src="../assets/blog-1.webp"></picture>
            </div>
            <p class="writter-content">
              While WhatsApp provides various features for staying connected with our contacts, many users seek additional functionalities. To cater to this demand, modified apps like WhatsApp Plus and GBWhatsApp have been developed. However, these modified apps come with their drawbacks, and prolonged use can result in account suspension. 
            </p>
            <p class="writter-content">
              If your account has been suspended, it's important to learn <strong>how to transfer GBWhatsApp messages to WhatsApp</strong>. You can refer to this guide, which outlines different methods for transferring GBWhatsApp to new or existing phones.
            </p>

            <h2 class="intro-title blog">
              How is GBWhatsApp different from WhatsApp?
            </h2>

            <p class="writter-content">
              GBWhatsApp is a modified version of WhatsApp that is released by a third-party developer. It offers a wide range of features that are presently absent in the standard WhatsApp app. Since the app is not available on Play Store (as it violates its terms), you need to download it from a trusted third-party source. 
            </p>
            <p class="writter-content">
              Here are some of the major features of GBWhatsApp that are presently not available in WhatsApp.
            </p>
            <ul>
              <li>Users can have two WhatsApp numbers in one phone</li>
              <li>Ghost Mode is a new feature for users to hide activity and online stauts</li>
              <li>Can share up to 50 MB of videos and 100 MB of audio files in one go</li>
              <li>Save WhatsApp status with a click</li>
              <li>Customized fonts, ticks, and stickers</li>
              <li>Share up to 90 pictures at one time.</li>
              <li>Send automatic reply messages to your contacts</li>
              <li>It allows you to read deleted messages</li>
              <li>Tons of color themes, and numerous other features that are not available in WhatsApp</li>
            </ul>

            <h2 class="intro-title blog">
              Methods to Transfer GB WhatsApp data to PC
            </h2>

            <p class="writter-content">
              First, open GB WhatsApp and navigate to Settings > Chats > Chat Backup to create a backup of your data. 
            </p>

            <p class="writter-content">
              Next, connect your Android device to your PC using a USB cable, ensuring file transfer mode is enabled. 
            </p>

            <p class="writter-content">
              Access your phone's storage from your PC, locate the GBWhatsApp folder, and copy it to a desired location on your computer. 
            </p>
            <p class="writter-content">
              Alternatively, use third-party file transfer apps like AirDroid or Google Drive to upload the backup and download it to your PC. This ensures your data is safely stored on your computer for future use.
            </p>

            <h2 class="intro-title blog">
              Limitations of GBWhatsApp
            </h2>

            <p class="writter-content">
              While GBWhatsApp offers so many advanced features, there are certain things that you should know in advance:
            </p>
            <ul>
              <li>The app is not licensed by the original WhatsApp developers. So, if you keep using this app, your account may be banned. </li>
              <li>Google Drive backup method is not available anymore.</li>
              <li>You can't access the new features of WhatsApp by updating the app. Ideally, you need to manually uninstall and reinstall GBWhatsApp to update it.</li>
            </ul>

            <div class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons">
              <div class="wp-block-button">
                <a id="dl" class="wp-block-button__link wp-element-button"  rel="dl noopener" href="https://gbwhatsapks.net/download-gbwhatsapp/">Download Page</a>
              </div>
            </div>
            <PostPagination :currentPage="0" />

          </section>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbwhatsapks.net/disclaimer/">Disclaimer</a>
            |
            <a href="https://gbwhatsapks.net/about-us/">About Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbwhatsapks.net/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
  <div v-if="$global.isMobileDevice()" style="height: 50px" aria-hidden="true" class="wp-block-spacer" />


  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" href="https://gbwhatsapks.net/download-gbwhatsapp/">
    Download GBWhatsapp APK
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import PostPagination from '@/components/PostPagination.vue';
import Breadcrumb from '@/components/BreadCrumb.vue';

export default {
  name: 'Home',
  components: {
    PostPagination,
    Breadcrumb
  },
  data() {
    return {
      pageName: 'home',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
      currentPage: 0,
    };
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }
  },
  methods: {
    downloadURL() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },

    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
